import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const slides = [
  {
    category: "Technology",
    title: "Innovations that Bring Pleasure to All Peoples",
    description:
      "Lorem ipsum dosectetur adipisicing elit, sed do. Lorem ipsum dolor sit amet, consectetur Nulla fringilla purus at leo dignissim congue.",
    author: "Jessie Bond",
    date: "18 May 2022",
    image: "images/hero-image/heroImg-1.jpg",
    avatar: "images/avatar/4.jpg",
  },
  {
    category: "Politics",
    title: "Here's the proof that momentum strategies work",
    description:
      "Lorem ipsum dosectetur adipisicing elit, sed do. Lorem ipsum dolor sit amet, consectetur Nulla fringilla purus at leo dignissim congue.",
    author: "Ann Kowalsky",
    date: "05 April 2022",
    image: "images/hero-image/homeImg-2.jpg",
    avatar: "images/avatar/3.jpg",
  },
  {
    category: "Politics",
    title: "How Maps Reshape American Politics In World",
    description:
      "Lorem ipsum dosectetur adipisicing elit, sed do. Lorem ipsum dolor sit amet, consectetur Nulla fringilla purus at leo dignissim congue.",
    author: "Mark Rose",
    date: "22 March 2022",
    image: "images/hero-image/homeImg-3.jpg",
    avatar: "images/avatar/2.jpg",
  },
  {
    category: "Business",
    title: "First prototype flight using kinetic launch system",
    description:
      "Lorem ipsum dosectetur adipisicing elit, sed do. Lorem ipsum dolor sit amet, consectetur Nulla fringilla purus at leo dignissim congue.",
    author: "Jane Taylor",
    date: "15 March 2022",
    image: "images/hero-image/homeImg-4.jpg",
    avatar: "images/avatar/1.jpg",
  },
  {
    category: "Business",
    title: "First prototype flight using kinetic launch system",
    description:
      "Lorem ipsum dosectetur adipisicing elit, sed do. Lorem ipsum dolor sit amet, consectetur Nulla fringilla purus at leo dignissim congue.",
    author: "Jane Taylor",
    date: "15 March 2022",
    image: "images/hero-image/homeImg-5.jpg",
    avatar: "images/avatar/1.jpg",
  },
  {
    category: "Business",
    title: "First prototype flight using kinetic launch system",
    description:
      "Lorem ipsum dosectetur adipisicing elit, sed do. Lorem ipsum dolor sit amet, consectetur Nulla fringilla purus at leo dignissim congue.",
    author: "Jane Taylor",
    date: "15 March 2022",
    image: "images/hero-image/homeImg-6.jpg",
    avatar: "images/avatar/1.jpg",
  },
  {
    category: "Business",
    title: "First prototype flight using kinetic launch system",
    description:
      "Lorem ipsum dosectetur adipisicing elit, sed do. Lorem ipsum dolor sit amet, consectetur Nulla fringilla purus at leo dignissim congue.",
    author: "Jane Taylor",
    date: "15 March 2022",
    image: "images/hero-image/homeImg-7.jpg",
    avatar: "images/avatar/1.jpg",
  },
  {
    category: "Business",
    title: "First prototype flight using kinetic launch system",
    description:
      "Lorem ipsum dosectetur adipisicing elit, sed do. Lorem ipsum dolor sit amet, consectetur Nulla fringilla purus at leo dignissim congue.",
    author: "Jane Taylor",
    date: "15 March 2022",
    image: "images/hero-image/homeImg-8.jpg",
    avatar: "images/avatar/1.jpg",
  },
  {
    category: "Business",
    title: "First prototype flight using kinetic launch system",
    description:
      "Lorem ipsum dosectetur adipisicing elit, sed do. Lorem ipsum dolor sit amet, consectetur Nulla fringilla purus at leo dignissim congue.",
    author: "Jane Taylor",
    date: "15 March 2022",
    image: "images/hero-image/homeImg-9.jpg",
    avatar: "images/avatar/1.jpg",
  },
  {
    category: "Business",
    title: "First prototype flight using kinetic launch system",
    description:
      "Lorem ipsum dosectetur adipisicing elit, sed do. Lorem ipsum dolor sit amet, consectetur Nulla fringilla purus at leo dignissim congue.",
    author: "Jane Taylor",
    date: "15 March 2022",
    image: "images/hero-image/homeImg-10.jpg",
    avatar: "images/avatar/1.jpg",
  },
  {
    category: "Business",
    title: "First prototype flight using kinetic launch system",
    description:
      "Lorem ipsum dosectetur adipisicing elit, sed do. Lorem ipsum dolor sit amet, consectetur Nulla fringilla purus at leo dignissim congue.",
    author: "Jane Taylor",
    date: "15 March 2022",
    image: "images/hero-image/homeImg-11.jpg",
    avatar: "images/avatar/1.jpg",
  },
];

const HeroSection = () => {
  return (
    <div id="wrapper" style={{ padding: "0px" }}>
      <Wrapper style={{ padding: "0px" }}>
        <div className="content">
          <div className="hero-slider-wrap fl-wrap">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              navigation
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000, // Speed of autoplay (2s)
                disableOnInteraction: false, // Keep autoplay running even after interaction
                pauseOnMouseEnter: false, // Prevent pausing when hovered
              }}
              loop={true} // Ensures infinite scrolling
              className="hero-slider-container multi-slider fl-wrap full-height"
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="bg-wrap">
                    <div
                      className="bg"
                      style={{
                        backgroundImage: `url(${slide.image})`,
                      }}
                    ></div>
                    <div className="overlay"></div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.section`
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    /* margin-top: -15px; */
    z-index: 10;
    cursor: pointer;
    /* background-size: 18px 44px !important; */
    background-size: 10px 44px !important;
    color: #fff !important;
    background-color: #2a276c !important;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid #0777f4 !important;
    border-radius: 5px !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #fff !important;
    z-index: 1000 !important;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: #fff !important;
    font-size: 28px;
    font-weight: bold;
  }
  .post-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  @media only screen and (max-width: 630px) {
    .swiper-button-next,
    .swiper-button-prev {
      width: 30px;
      height: 30px;
    }

    section {
      float: left;
      width: 100%;
      position: relative;
      padding: 0 !important;
      z-index: 10;
      overflow: hidden;
    }
  }
`;

export default HeroSection;
