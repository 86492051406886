import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PiCaretLineLeftBold, PiCaretLineRightBold } from "react-icons/pi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import blogsArray from "../../blogData.js";

const AllIssues = () => {
  const [blogs, setBlogs] = useState([]);

  // useEffect(() => {
  //   setBlogs(blogsArray);
  // }, []);

  useEffect(() => {
    setBlogs([...blogsArray].reverse());
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const totalPages = Math.ceil(blogs.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogs.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <Wrapper style={{ padding: "0" }}>
      <div id="wrapper" style={{ padding: "0" }}>
        <div className="content" style={{ marginTop: "70px" }}>
          <div className="breadcrumbs-header fl-wrap">
            <div className="container">
              <div className="breadcrumbs-header_url">
                <Link to="/">Home</Link>
              </div>
            </div>
          </div>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="main-container fl-wrap fix-container-init">
                    <div className="section-title">
                      <h2>Recent Publications</h2>
                    </div>

                    <div className="list-post-wrap">
                      {currentItems &&
                        currentItems.map((news) => {
                          return (
                            <div key={news.id} className="list-post fl-wrap">
                              <div className="list-post-media">
                                <Link to={`/all-issues-details/${news.id}`}>
                                  <div className="bg-wrap">
                                    <div
                                      className="bg"
                                      style={{
                                        backgroundImage: `url(${news.image})`,
                                      }}
                                    ></div>
                                  </div>
                                </Link>
                                <span className="post-media_title">
                                  &copy; {news.copyright}
                                </span>
                              </div>
                              <div className="list-post-content">
                                <Link className="post-category-marker" to="#">
                                  {news.category}
                                </Link>
                                <h3>
                                  <Link to={`/all-issues-details/${news.id}`}>
                                    {news.title}
                                  </Link>
                                </h3>
                                <span className="post-date">
                                  <i className="far fa-clock"></i> {news.date}
                                </span>

                                <div className="author-link">
                                  <Link to="#">
                                    <img
                                      src={news.authorImage}
                                      alt={news.author}
                                      style={{
                                        border: "1px solid #2A276C",
                                      }}
                                    />
                                    <span>{news.author}</span>
                                  </Link>
                                </div>
                                {/* <div className="text-start">
                                    <button className="text-danger">PDF</button>
                                  </div> */}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="pagination d-flex justify-content-between align-items-center mt-3 w-100">
                    <div className="d-flex align-items-center gap-1">
                      <span className="pagination_text w-100">
                        Rows per page:
                      </span>
                      <select
                        className="form-select ms-2"
                        value={itemsPerPage}
                        onChange={(e) => {
                          setItemsPerPage(Number(e.target.value));
                          setCurrentPage(1);
                        }}
                      >
                        {[10, 20, 30, 50].map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                    </div>

                    <span className="pagination-info">
                      {indexOfFirstItem + 1}-
                      {Math.min(indexOfLastItem, blogs.length)} of{" "}
                      {blogs.length}
                    </span>

                    <div className="pagination-buttons d-flex gap-2">
                      <button
                        className="btn btn-link"
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                      >
                        <PiCaretLineLeftBold />
                      </button>
                      <button
                        className="btn btn-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <FaChevronLeft />
                      </button>
                      <button
                        className="btn btn-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <FaChevronRight />
                      </button>
                      <button
                        className="btn btn-link"
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                      >
                        <PiCaretLineRightBold />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="sidebar-content fl-wrap fixed-bar">
                    <div className="box-widget fl-wrap">
                      <div className="box-widget-content">
                        <div className="search-widget fl-wrap">
                          <form>
                            <input
                              name="se"
                              id="se12"
                              type="text"
                              className="search"
                              placeholder="Search..."
                              defaultValue=""
                            />
                            <button
                              className="search-submit2"
                              id="submit_btn12"
                            >
                              <i className="far fa-search"></i>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="limit-box fl-wrap"></div>
            </div>
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  /* ===== pagination css ===== */
  .pagination {
    margin: 20px 0 0 0;
    text-align: center;
    float: left;
    z-index: 10;
    position: relative;
    /* background: #222; */
    background: none !important;
    overflow: hidden;
    border-radius: 4px;
  }
  .form-select {
    padding: 6px 6px !important;
  }
  .btn {
    padding: 10px 14px !important;
  }

  .btn-link {
    background-color: #2a276c !important;
    color: #fff !important;
    border: none;
    cursor: pointer;
  }
  .btn-link:hover {
    background-color: #424141 !important;
    color: #fff;
  }
  .d-flex {
    display: flex;
  }
  .gap-2 {
    gap: 8px;
  }
  @media screen and (max-width: 425px) {
    .texxt {
      font-size: 10px !important;
    }
    .pagination_text {
      display: none !important;
    }
    .pagination-info {
      font-size: 10px !important;
    }
    .btn-link {
      padding: 2px 8px !important;
    }
    .form-select {
      padding: 6px 20px !important;
    }
    .btn {
      padding: 11px 15px !important;
    }
  }
  @media screen and (max-width: 320px) {
    .pagination-buttons {
      gap: 5px !important;
    }
    .form-select {
      padding: 6px 20px !important;
    }
    .btn {
      padding: 10px 12px !important;
    }
  }
`;

export default AllIssues;
