import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const SubmissionGuidelines = () => {
  return (
    <Wrapper style={{ padding: "0" }}>
      <div id="wrapper" style={{ padding: "0" }}>
        <div className="content" style={{ marginTop: "70px" }}>
          <div className="breadcrumbs-header fl-wrap">
            <div className="container">
              <div className="breadcrumbs-header_url">
                <Link to="/">Home</Link>
                {/* <span>Submission Guidelines</span> */}
              </div>
            </div>
            <div className="pwh_bg"></div>
          </div>

          <section>
            <div className="container">
              <div className="row">
                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>1. Article Types</h2>
                  </div>
                  <div>
                    <ul
                      style={{ listStyleType: "disc", paddingLeft: "20px" }}
                      className="ppp_text"
                    >
                      <li className="mb-2">
                        <span className="fw-bold">Research papers:</span>
                        &nbsp;Must not be more than 10,000 words, including
                        tables, charts, diagrams, graphs, and references.
                      </li>
                      <li className="mb-2">
                        <span className="fw-bold">
                          Review/ Analysis/ Articles:
                        </span>
                        &nbsp;Must be between 3000 - 5,000 words, including
                        tables, charts, diagrams, graphs, and references.
                      </li>
                      <li className="mb-2">
                        <span className="fw-bold">
                          Thematic discussions/ Case notes/ Commentary:
                        </span>
                        &nbsp;Must be between 2000 - 3000 words.
                      </li>
                      <li className="mb-2">
                        <span className="fw-bold">Book reviews:</span>
                        &nbsp;The desirable length for book reviews is up to
                        2,000 words.
                      </li>
                    </ul>
                  </div>

                  {/* <div className="about-wrap">
                      <p className="ppp_text">
                        The journal considers all submissions based on topic,
                        academic content, and style. The promising articles
                      </p>
                    </div> */}
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>1. Manuscript Preparation</h2>
                  </div>
                  <p className="ppp_text">
                    The author must prepare the manuscript in MS Word format
                    (Font: Times New Roman, Size: 12, Line Spacing: 1.5, Format:
                    docx). No PDF or LaTex format will be accepted. Three
                    separate documents need to be provided:
                  </p>
                  <div>
                    <ul
                      style={{ listStyleType: "disc", paddingLeft: "20px" }}
                      className="ppp_text"
                    >
                      <li className="mb-2">
                        <span className="fw-bold">Author Page:</span>
                        &nbsp;(Include the author’s name, ORCID ID, academic
                        degrees, current affiliations/professions with the
                        details of their respective institution, email, and
                        contact number)
                      </li>
                      <li className="mb-2">
                        <span className="fw-bold">Manuscript:</span>
                        &nbsp; (include the title, abstract not exceeding 250
                        words, 4-6 keywords, main articles including
                        introduction, main body, and a conclusion). Chicago
                        referencing style is preferred. No “in-text” citation
                        allowed, use footnotes instead.
                      </li>
                      <li className="mb-2">
                        <span className="fw-bold">Bibliography:</span>
                        &nbsp; (Include only the bibliography by maintaining the
                        Chicago referencing style).
                      </li>
                    </ul>
                  </div>

                  {/* <div className="about-wrap">
                      <p className="ppp_text">
                        The journal considers all submissions based on topic,
                        academic content, and style. The promising articles
                      </p>
                    </div> */}
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>3. Article Processing Fee (APC)</h2>
                  </div>
                  <p className="ppp_text">
                    Our efficient peer-review system ensures a quick turnaround
                    for your manuscript. If your work receives positive
                    feedback, SAJLP will notify you for a nominal publication
                    fee to secure its immediate publication. This fee is payable
                    within 5-10 business days. Upon receiving payment, the
                    author will receive an acceptance certificate directly from
                    the Editor-in-Chief (editor.sajlp@gmail.com). Please note,
                    that we prioritize transparency and avoid third-party
                    involvement. SAJLP is the sole service provider for
                    publication fees. Financial Hardship? We understand. Facing
                    financial constraints shouldn't hinder your research
                    journey. Authors facing financial hardship can apply for a
                    waiver. Simply contact the Editor-in-Chief soon after
                    receiving the payment notification. To ensure a smooth
                    publication process, please submit your payment or waiver
                    request within 5-10 business days. Failure to do so will
                    result in manuscript rejection due to non-payment.
                  </p>

                  <p className="ppp_text">
                    The Article Processing Charge (APC) for publishing within
                    this journal is currently $300 (*in 2024). This fee covers
                    all publication costs, including the editorial process, web
                    hosting, indexing, marketing, archiving, etc., and ensures
                    that all of the content is fully open access. This approach
                    maximizes the potential readership of publications and
                    allows the journal to be run sustainably. Many institutions
                    have funds available to support open-access publications;
                    therefore, we ask the authors to contact their department
                    and institutional library, along with their funding body (if
                    applicable), to check whether open-access publication fees
                    are available to them. However, waivers are available for
                    authors who can adequately demonstrate financial need. To
                    avail of that waiver, please write a formal request to the
                    Editor-in-Chief. For details, please check the “Submission &
                    Review Process”.
                  </p>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>4. Important Information for the Author</h2>
                  </div>
                  <p className="ppp_text">
                    The editorial team will review the manuscript and decide
                    whether it is acceptable for single-blind peer review.
                    Manuscripts will be rejected immediately if the “manuscript
                    preparation” is not followed. This decision usually takes
                    4–8 weeks from the receipt of the submission. The authors
                    will be notified of this decision. By submitting the
                    manuscript, the author complies with the copyright and
                    privacy statements by the SAJLP authority.
                  </p>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>5. Submission</h2>
                  </div>
                  <p className="ppp_text">
                    Submit the manuscript in the required format ( check the
                    author guidelines ) to
                    <Link className="linnk" to="mailto:editor.sajlp@gmail.com">
                      {" "}
                      editor.sajlp@gmail.com
                    </Link>
                    , with a cc to
                    <Link
                      className="linnk"
                      to="mailto:sajlp.repository@gmail.com"
                    >
                      {" "}
                      sajlp.repository@gmail.com
                    </Link>
                    .
                  </p>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>6. Contact</h2>
                  </div>

                  <div>
                    <ul
                      style={{ listStyleType: "disc", paddingLeft: "20px" }}
                      className="ppp_text"
                    >
                      <li className="mb-2">
                        <strong>Principle Contact:</strong> Editorial Team,
                        <Link
                          className="linnk"
                          to="mailto:editor.sajlp@gmail.com"
                        >
                          {" "}
                          editor.sajlp@gmail.com
                        </Link>
                        .
                      </li>
                      <li className="mb-2">
                        <strong>Support/Complaint:</strong> Management & Policy
                        Chief,
                        <Link
                          className="linnk"
                          to="mailto:aditya.oikya@gmail.com"
                        >
                          {" "}
                          aditya.oikya@gmail.com
                        </Link>
                        , with a cc to
                        <Link
                          className="linnk"
                          to="mailto:editor.sajlp@gmail.com"
                        >
                          {" "}
                          editor.sajlp@gmail.com
                        </Link>
                        .
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="sec-dec"></div>
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .section-title {
    margin-bottom: 20px !important;
  }
  .ppp_text {
    text-align: justify !important;
  }
  .linnk {
    color: #0563c1;
  }
  .textt {
    font-size: 17px !important;
    font-family: serif !important;
    color: #000 !important;
    font-weight: 700 !important;
    text-align: start !important;
  }
  .texxt {
    font-size: 17px !important;
    font-family: serif;
    /* font-style: italic; */
    color: #000 !important;
    /* font-weight: 400 !important; */
    text-align: start !important;
    margin-left: 10px !important;
  }
  .teext {
    font-size: 17px !important;
    font-family: serif !important;
    color: #0563c1 !important;
    font-weight: 500 !important;
    text-align: start !important;
    margin-left: 10px !important;
  }
  .ppp_text {
    font-size: 14px !important;
    color: #000 !important;
  }
  .ulll_content {
    margin-left: 10px !important;
  }
  .ppp_textt {
    font-size: 14px !important;
    color: #000 !important;
    margin-left: 30px !important;
  }
`;

export default SubmissionGuidelines;
